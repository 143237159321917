<script>
import axios from 'axios';

export default {
  data() {
    return {
      showAlert: false,

      linkFacebook: '',
      linkTwitter: '',
      linkInstagram: '',
      linkGithub: '',
      linkLinkedin: '',
      linkTelegram: ''
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  watch: { 
    data: function() {
      this.fillFieldsFromProps();
    }
  },
  mounted: function() {
    this.fillFieldsFromProps();
  },
  components: {
  },
  methods: {
    getField(type) {
      if(this.data && this.data.links && this.data.links.length > 0) {
        var item = this.data.links.filter( (e) => e.type === type);
        if(item.length === 1)
          return item[0].url;
      }
      return '';
    },
    fillFieldsFromProps: function() {
      this.linkFacebook = this.getField(1);
      this.linkTwitter = this.getField(2);
      this.linkInstagram = this.getField(3);
      this.linkLinkedin = this.getField(4);
      this.linkGithub = this.getField(5);
      this.linkTelegram = this.getField(6);
    },
    backToPodcast: function() {
      this.$router.push('/podcast/' + this.$route.params.name);
    },
    sendDetailsForm: function() {
      var r = this.validateForm();
      if(r) {
          var data = {
            facebook: this.linkFacebook,
            twitter: this.linkTwitter,
            instagram: this.linkInstagram,
            github: this.linkGithub,
            linkedin: this.linkLinkedin,
            telegram: this.linkTelegram
          };

          const vm = this;
          const vmParent = this.$parent;
          axios.post('/api/management/podcast/links/' + this.$route.params.name, data, { withCredentials: true }).then(function () {
              vm.showAlert = true;
              vmParent.$emit('refreshPodcast');
          });
      }
    },
    validateForm: function () {
      return true;
    },
  },
};
</script>

<template>
  <b-alert :show="showAlert" variant="success" dismissible>
      <strong>Success!</strong> Form was saved
  </b-alert>

  <form v-on:submit.prevent="sendDetailsForm">
    <div class="row">
      <div class="col-lg-12">
        <div class="mb-3">
          <label for="linkFacebook" class="form-label">Facebook</label>
          <input type="text" class="form-control text-primary" id="linkFacebook" placeholder="" v-model="linkFacebook">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="mb-3">
          <label for="linkTwitter" class="form-label">Twitter</label>
          <input type="text" class="form-control text-primary" id="linkTwitter" placeholder="" v-model="linkTwitter">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="mb-3">
          <label for="linkInstagram" class="form-label">Instagram</label>
          <input type="text" class="form-control text-primary" id="linkInstagram" placeholder="" v-model="linkInstagram">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="mb-3">
          <label for="linkGithub" class="form-label">Github</label>
          <input type="text" class="form-control text-primary" id="linkGithub" placeholder="" v-model="linkGithub">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="mb-3">
          <label for="linkLinkedin" class="form-label">LinkedIn</label>
          <input type="text" class="form-control text-primary" id="linkLinkedin" placeholder="" v-model="linkLinkedin">
        </div>
      </div>
      <div class="col-lg-12">
        <div class="mb-3">
          <label for="linkTelegram" class="form-label">Telegram</label>
          <input type="text" class="form-control text-primary" id="linkTelegram" placeholder="" v-model="linkTelegram">
        </div>
      </div>
    </div>
    <div class="col-lg-12">
      <div class="hstack gap-2 justify-content-end">
        <button type="submit" class="btn btn-primary"> Update </button>
        <button type="button" class="btn btn-light" v-on:click="backToPodcast">Cancel</button>
      </div>
    </div>
  </form>
</template>